<template>
  <b-row>
    <b-col md="5">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="transportationForm inputWhite"
          @submit.prevent="handleSubmit(submitUSerInfo)"
        >
          <b-row>
            <b-col md="12">
              <input-form
                type="text"
                v-model="userInfo.userName"
                :label="$t('auth.fullName')"
                :validate="{ required: true, regex: /[^0-9.]+/g }"
                id="userName"
                :placeholder="$t('auth.fullName')"
                :name="$t('auth.fullName')"
              />
            </b-col>
            <b-col md="12">
              <country-code
                :label="$t('auth.phoneNumber')"
                :placeholder="$t('auth.phoneNumber')"
                validate="required|numeric"
                :name="$t('auth.phoneNumber')"
                v-model="userInfo.phone"
                id="phone-code-primary_phone_number"
                @onSelect="onSelect"
              />
              <!-- <div class="d-flex mb-3">
          <input-form class="flex-1 flex-grow-1 phone-input" type="text" v-model="userInfo.phone" :label="$t('auth.phoneNumber')"
                      :placeholder="$t('auth.phoneNumber')" validate="required|numeric" id="phone"  :name="$t('auth.phoneNumber')" />
          <vue-country-code
              v-model="userInfo.phoneCode"
              @onSelect="onSelect"
              :onlyCountries="['sa', 'eg']"
              :dropdownOptions="{ disabledDialCode: true }"
              :enabledCountryCode= true
              defaultCountry="sa"
              class="testphone"
          />
          </div> -->
            </b-col>
            <b-col md="12">
              <div class="form-group">
                <label class="input-with-icon-label mb-2">{{
                  $t("transportation.transportationDate")
                }}</label>
                <!--            <validation-provider-->
                <!--                #default="{ errors }"-->
                <!--                name="dateTime"-->
                <!--                rules="required"-->
                <!--                ref="dateTime"-->
                <!--            >-->
                <div
                  :class="[
                    'd-flex align-items-center input-with-icon-container iq-border-radius-10 overflow-hidden',
                  ]"
                >
                  <flat-pickr
                    v-model="userInfo.dateTime"
                    validate="required"
                    :name="$t('transportation.transportationDate')"
                    :config="{ minDate: 'today' }"
                    class="form-control form-date input-with-icon rounded-0 border-0"
                  ></flat-pickr>
                  <div
                    class="icon d-flex align-items-center justify-content-center"
                  >
                    <i class="las la-calendar"></i>
                  </div>
                </div>
                <!--              <small class="text-danger">{{ errors[0] }}</small>-->
                <!--            </validation-provider>-->
              </div>
            </b-col>
            <b-col md="12">
              <div class="form-group">
                <label class="input-with-icon-label mb-2">{{
                  $t("transportation.transportationTime")
                }}</label>
                <div
                  :class="[
                    'd-flex align-items-center input-with-icon-container iq-border-radius-10 overflow-hidden',
                  ]"
                >
                  <flat-pickr
                    class="form-control form-date input-with-icon rounded-0 border-0"
                    v-model="userInfo.time"
                    validate="required"
                    :name="$t('transportation.transportationTime')"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'h:i K',
                    }"
                  ></flat-pickr>
                  <div
                    class="icon d-flex align-items-center justify-content-center"
                  >
                    <i class="las la-clock"></i>
                  </div>
                </div>
                <!--              <small class="text-danger">{{ errors[0] }}</small>-->
                <!--            </validation-provider>-->
              </div>
            </b-col>
            <b-col md="12">
              <div class="form-group">
                <label for="startAddress" class="input-with-icon-label mb-2">{{
                  $t("transportation.startLocation")
                }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="endAddress"
                  rules="required"
                  ref="endAddress"
                >
                  <div
                    :class="[
                      'd-flex align-items-center input-with-icon-container iq-border-radius-10 overflow-hidden',
                      { 'is-invalid border-1': errors.length > 0 },
                    ]"
                  >
                    <input
                      type="text"
                      id="startAddress"
                      v-model="userInfo.startAddress"
                      class="form-control input-with-icon rounded-0 border-0"
                    />
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-map-marker"></i>
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="12">
              <div class="form-group">
                <label for="endAddress" class="input-with-icon-label mb-2">{{
                  $t("transportation.endLocation")
                }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="startAddress"
                  rules="required"
                  ref="startAddress"
                >
                  <div
                    :class="[
                      'd-flex align-items-center input-with-icon-container iq-border-radius-10 overflow-hidden',
                      { 'is-invalid border-1': errors.length > 0 },
                    ]"
                  >
                    <input
                      type="text"
                      v-model="userInfo.endAddress"
                      id="endAddress"
                      class="form-control input-with-icon rounded-0 border-0"
                    />
                    <div
                      class="icon d-flex align-items-center justify-content-center"
                    >
                      <i class="las la-map-marker"></i>
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="12" class="d-flex justify-content-center">
              <p
                class="bg-white p-2 w-75 mt-2 d-flex justify-content-between iq-border-radius-10"
              >
                <span>{{ $t("transportation.tripDistance") }}</span>
                <span v-if="info && info.status === 'OK'">
                  {{ info.distance.text }}
                </span>
              </p>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center">
            <b-button
              variant="primary"
              type="submit"
              class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold"
            >
              <span class="mx-2"> {{ $t("transportation.continue") }}</span>
              <i class="las la-angle-left"></i>
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-col>
    <b-col md="7">
      <div class="map-container iq-border-radius-10">
        <div id="map" ref="map"></div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/*eslint-disable*/
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { core } from "@/config/pluginInit";
export default {
  data() {
    return {
      config: {
        altInput: true,
        dateFormat: "Y-m-d",
        enableTime: true,
      },
      userInfo: {
        userName: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        phone: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
        phoneCode: "",
        dateTime: null,
        time: "",
        startAddress: "",
        endAddress: "",
        transportationPlace: "",
      },
      start: "",
      end: "",
      startText: "",
      endText: "",
      info: {},
    };
  },
  components: {
    flatPickr,
  },
  methods: {
    onSelect(data) {
      this.code = data;
    },
    submitUSerInfo() {
      if (this.userInfo.dateTime === null) {
        core.showSnackbar("error", "يجب أختيار تاريخ النقل");
      } else if (this.userInfo.startAddress === this.userInfo.endAddress) {
        core.showSnackbar("error", "لا يجب اختيار نفس الموقع");
      } else if (this.info.status !== "OK") {
        core.showSnackbar("error", " يجب اختيار مكان صحيح");
      } else {
        this.$emit("changeForm", {
          ...this.info,
          ...this.userInfo,
          startPoint: this.start,
          endPoint: this.end,
        });
      }
    },
    drawMap() {
      // configuration map
      const styleMap = [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#e9e9e9",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ];
      const location = { lat: 30.064742, lng: 31.249509 };
      const mapZoom = 12;

      // configuration map
      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: mapZoom,
        center: location,
        styles: styleMap,
      });
    },
    calculateAndDisplayRoute(directionsService, directionsDisplay) {
      if (this.userInfo.startAddress && this.userInfo.endAddress) {
        directionsService.route(
          {
            origin: this.userInfo.startAddress,
            destination: this.userInfo.endAddress,
            travelMode: "DRIVING",
          },
          function (response, status) {
            if (status === "OK") {
              directionsDisplay.setDirections(response);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }
      var service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [this.start],
          destinations: [this.end],
          travelMode: "DRIVING",
        },
        (res) => {
          this.info = res.rows[0].elements[0];
        }
      );
    },
    calculateDistance() {
      // configuration map
      const styleMap = [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#e9e9e9",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ];
      var directionsService = new window.google.maps.DirectionsService();
      var directionsDisplay = new window.google.maps.DirectionsRenderer();
      var map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 7,
        center: { lat: 41.85, lng: -87.65 },
        styles: styleMap,
      });
      directionsDisplay.setMap(map);
      if (this.userInfo.startAddress && this.userInfo.endAddress) {
        this.calculateAndDisplayRoute(directionsService, directionsDisplay);
      }
    },
  },
  mounted() {
    this.drawMap();
  },
  watch: {
    "userInfo.startAddress"() {
      const originAutoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById("startAddress"),
        {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
        }
      );
      originAutoComplete.addListener("place_changed", () => {
        var place = originAutoComplete.getPlace();
        this.startText = place.formatted_address;
        this.start = place.geometry.location
          .toString()
          .replace("(", "")
          .replace(")", "");
        this.userInfo.startAddress = place.formatted_address;
        this.calculateDistance();
      });
      // this.calculateDistance()
    },
    "userInfo.endAddress"() {
      const originAutoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById("endAddress"),
        {
          fields: ["formatted_address", "geometry", "name"],
          strictBounds: false,
        }
      );
      originAutoComplete.addListener("place_changed", () => {
        var place = originAutoComplete.getPlace();
        this.endText = place.formatted_address;
        this.end = place.geometry.location
          .toString()
          .replace("(", "")
          .replace(")", "");
        this.userInfo.endAddress = place.formatted_address;
        this.calculateDistance();
      });
    },
  },
};
</script>

<style lang="scss">
.testphone {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
  margin-top: 33px;
}
.phone-input .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.input-with-icon-label {
  font-size: 20px;
}
.input-with-icon-container {
  background-color: #fff;
  height: 45px;
  border: 1px solid #d7dbda;
  .input-with-icon {
    height: 100%;
    background: transparent !important;
    flex: 1;
    padding-inline-start: 20px !important;
    &.border-0 {
      border: none !important;
    }
  }
  .icon {
    min-width: 40px;
    height: 100%;
    font-size: 23px;
  }
}
.map-container {
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 20px #0000002c;
}
#map {
  height: 100%;
}
.is-invalid {
  border-color: #dc3545;
}
</style>
