<template>
  <div>
    <b-modal id="vehicleInfo" centered hide-header size="lg" hide-footer>
      <b-row>
        <b-col md="3">
          <p class="text-black font-size-22">
            {{ $t("transportation.vehicleType") }}
          </p>
        </b-col>
        <b-col md="9">
          <p class="text-black font-size-22">{{ vehicleInfo.model }}</p>
        </b-col>
        <b-col md="3">
          <p class="text-black font-size-22">
            {{ $t("transportation.vehicleYear") }}
          </p>
        </b-col>
        <b-col md="9">
          <p class="text-black font-size-22">{{ vehicleInfo.year }}</p>
        </b-col>
        <b-col md="3">
          <p class="text-black font-size-22">
            {{ $t("transportation.vehicleNumber") }}
          </p>
        </b-col>
        <b-col md="9">
          <p class="text-black font-size-22">{{ vehicleInfo.plateNumber }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" v-for="(i, key) in vehicleInfo.images" :key="key">
          <img :src="i" class="w-100" />
        </b-col>
      </b-row>
    </b-modal>
    <b-alert
      variant="success"
      show
      class="accepted-journy-alert d-flex justify-content-between align-items-center flex-wrap gap_1 mb-5"
    >
      <h4 class="accepted-journy-title">
        {{ $t("transportation.acceptTrip") }}
      </h4>
      <b-button class="border-0 truck-info-btn" @click="getVehicleInfo">{{
        $t("transportation.vehicleInfo")
      }}</b-button>
    </b-alert>
    <div class="fees-company-container">
      <div>
        <div
          class="d-flex justify-content-between align-items-end mb-3 flex-column flex-md-row"
        >
          <h3 class="fees-company-main-title text-gray m-0">
            {{ $t("payment.paymentOptions") }}
            <span class="note"
              ><strong class="ml-2"
                >{{ $t("transportation.attention") }} :</strong
              >
              {{ $t("transportation.payWithin") }}</span
            >
          </h3>
          <h3 class="trip-canceled-in m-0 text-left">
            <span>{{ $t("transportation.tripCancelIn") }}</span>
            <span class="d-block timer"
              >{{ hours }}<small>hr</small> {{ minutes }}<small>min</small>
              {{ seconds }}<small>sec</small></span
            >
          </h3>
        </div>
        <div
          class="fees-company-item py-3 mb-3 d-flex justify-content-start align-items-center"
        >
          <div class="d-flex align-items-center gap_2">
            <h6 class="text-gray font-weight-bold mr-4">
              {{ $t("transportation.amountPaid") }}
            </h6>
            <h4 class="text-gray font-weight-bold">
              {{ allInfoData.data.data.price }}
            </h4>
            <h6 class="text-gray font-weight-bold">{{ $t("endUser.rs") }}</h6>
          </div>
          <!--          <div class="d-flex align-items-center border inp-group iq-border-radius-10 overflow-hidden ">-->
          <!--            <input class="border-0 rounded-0 h-100 bg-transparent" type="text" placeholder="كود الخصم" v-model="codeActive"  />-->
          <!--            <b-button variant="primary" class="rounded-0 h-100" :disabled="!codeActive">تفعيل</b-button>-->
          <!--          </div>-->
        </div>
        <!-- <div class="fees-company-item py-3">
          <div class="d-flex justify-content-between mr-4 ml-4 border-bottom">
            <h5 class="text-gray font-weight-bold">بطاقات الإئتمان الخاصة بك</h5>
            <p class="text-primary font-size-18 mb-2" @click="$bvModal.show('add-new-card')"><i class="las la-plus font-weight-bold pl-1"></i>{{$t('payment.addNewCreditCard')}}</p>
          </div>

          <div class="d-flex flex-column mr-4 ml-4 border-bottom">
            <b-form-radio class="custom-radio-color-checked" inline v-model="selectedPayment" color="primary"
                          name="color" value="1">
              <div class="d-flex flex-column mr-3 pr-3 py-3">
                <div class=" d-flex align-items-center">
                  <div class="payment-mada-img">
                    <img src="@/assets/images/ibbil/payments/Mastercard.png" alt="img">
                  </div>
                  <p class="m-0 mr-2 text-primary">8888 **** **** ****</p>
                </div>
              </div>
            </b-form-radio>
            <b-form-radio class="custom-radio-color-checked" inline v-model="selectedPayment" color="primary"
                          name="color" value="2" >
              <div class="d-flex flex-column mr-3 pr-3 py-3">
                <div class=" d-flex align-items-center">
                  <div class="payment-visa-img">
                    <img src="@/assets/images/ibbil/payments/Visa_logo.png" alt="img">
                  </div>
                  <p class="m-0 mr-2 text-primary">8888 **** **** ****</p>
                </div>
              </div>
            </b-form-radio>
        </div>
        <div class="d-flex align-items-center justify-content-between mr-4 ml-4">
          <b-form-radio class="custom-radio-color-checked" inline v-model="selectedPayment" color="primary"
                        name="color" value="3" >
            <div class="d-flex flex-column mr-3 pr-3 py-3">
              <div class=" d-flex align-items-center">
                <div class="payment-mada-img">
                  <img :src="require('@/assets/images/ibbil/payments/mada-logo.png')" alt="img">
                </div>
                <h4 class="m-0 mr-2 font-weight-bold">الدفع بواسطة مدى</h4>
              </div>
              <p class="m-0 text-primary">سوف يتم تحصيل مبلغ 12.00 ر.س عند التحصيل النقدي</p>
            </div>
          </b-form-radio>
        </div>
        </div> -->
      </div>
    </div>
    <payment-component
      @payWithWallet="payWithWallet"
      @payWitCard="payWithWallet"
    />
    <!--    <div class="d-flex justify-content-center mb-5">-->
    <!--      <b-button variant="primary" type="submit" class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold">-->
    <!--        <span class="mx-2"> التالى</span>-->
    <!--        <i class="las la-angle-left"></i>-->
    <!--      </b-button>-->
    <!--    </div>-->
  </div>
</template>
<script>
/*eslint-disable*/
import paymentComponent from "@/components/payment";
import TransportationServices from "../../services/transportation";
export default {
  props: ["allInfoData"],
  data() {
    return {
      insurance_type: "",
      iban: "",
      disabled: false,
      disabledCompany: false,
      selectedPayment: "",
      vehicleInfo: {},
      options: [
        {
          text: "الدفع عند الإستلام",
          value: "first",
        },
        {
          text: "الدفع عند الإستلام",
          value: "first",
        },
      ],
      insuranceCompanyInfos: [
        {
          company: "one",
          logo: require("../../../../../assets/images/ibbil/elraghi.png"),
          insuredAmount: "5,000 ريال سعودى",
          certificationFees: "20 ريال سعودى",
          transportationCoverage: "300 ريال سعودى",
          tax: "45 ريال سعودى",
          total: "365 ريال سعودى",
        },
      ],
      codeActive: "",
      wallet: {
        panels: "",
        password: "",
      },
      showPassword: false,
      hours: null,
      minutes: null,
      seconds: null,
    };
  },
  components: {
    paymentComponent,
  },
  methods: {
    submitInstallment() {
      this.$emit("changeForm");
    },
    payWithWallet(data) {
      this.$emit("payWithWallet", data);
    },
    getVehicleInfo() {
      TransportationServices.getVehicleInfo(
        this.allInfoData.data.data.vehicleId
      ).then((res) => {
        this.vehicleInfo = res.data.data;
        this.$bvModal.show("vehicleInfo");
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    var self = this;
    var date1 = new Date();
    date1.setMinutes(date1.getMinutes() + 15);
    var countDownDate = new Date(date1).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      self.hours = hours;
      self.minutes = minutes;
      self.seconds = seconds;
      if (distance < 0) {
        clearInterval(x);
        // hna h3ml closeTrip
      }
    }, 1000);
  },
};
</script>

<style lang="scss">
.accepted-journy-alert {
  background-color: #6ebf971a !important;
  border: 1px solid #6ebf97bf !important;
  border-radius: 10px !important;
  height: 93px;
  padding: 21px;
  .accepted-journy-title {
    font-size: 25px !important;
    font-weight: 600 !important;
    color: #6ebf97 !important;
  }
  .truck-info-btn {
    min-width: 190px;
    height: 50px;
    background: #646464 !important;
    border-radius: 8px !important;
    color: #ffffff !important;
    font-size: 20px !important;
  }
}
.inp-group {
  height: 55px;
  padding-inline-start: 20px;
  font-size: 20px;
  background-color: var(--iq-bg-light-color);
  button {
    min-width: 100px;
    font-size: inherit !important;
  }
}
.fees-company-container {
  margin-bottom: 52px;
  .note {
    color: #d58a12;
    margin-inline-start: 30px;
    font-weight: 400;
  }
  .trip-canceled-in {
    font-size: 25px !important;
    color: #646464 !important;
    .timer {
      margin-top: -5px;
      color: #d39d45;
      font-weight: 600;
      small {
        margin: 0 4px;
      }
    }
  }
  .fees-company-main-title {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .fees-company-item {
    box-shadow: 0px 3px 20px #0000000d;
    padding: 45px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    &.selected {
      border-color: #6ebf97;
    }
    .fees-company-item-body {
      padding-bottom: 27px;
      margin-bottom: 16px;
      border-bottom: 1px solid #dedede;
    }
    .fees-company-item-info-box {
      margin-bottom: 22px;
      &.last {
        padding-bottom: 22px;
        border-bottom: 1px solid #dedede;
      }
    }
    .fees-company-item-title {
      color: #b1b1b1;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .fees-company-item-response {
      color: #9b9b9b;
      font-size: 20px;
      font-weight: bold;
    }
    .fees-company-item-img,
    .fees-company-item-info-total {
      .fees-company-item-title {
        font-size: 20px;
      }
      .fees-company-item-response {
        font-size: 20px;
      }
    }
    .fees-company-item-info-total {
      .fees-company-item-response {
        color: #d39d45;
      }
    }
    .fees-company-item-img {
      img {
        max-width: 150px;
      }
    }
    .fees-company-item-note {
      color: #646464;
      font-size: 16px;
      max-width: 290px;
    }
    .privacy-btn {
      font-size: 16px;
      color: #646464;
      padding-bottom: 3px;
      border-bottom: 1px solid #646464;
      font-weight: bold;
      margin-inline-end: 18px;
    }
    .choose {
      height: 50px;
      border: 1px solid #2b584280;
      border-radius: 10px;
      color: #2b5842;
      font-size: 20px;
      width: 111px;
      background-color: transparent;
    }
  }
}
.fees-card {
  background-color: #fff;
  box-shadow: 0px 3px 20px #0000000d;
  border-radius: 15px;
  .fees-card-btn {
    padding: 16px 20px !important;
    border-radius: inherit;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #494845 !important;
    .fees-card-btn-inner {
      width: 100%;
      white-space: nowrap;
      transition: 0.5s;
      img {
        min-width: 35px;
        margin-inline-end: 12px;
      }
    }
    &.not-collapsed {
      .fees-card-btn-inner {
        width: 0;
      }
      &::after {
        display: block;
      }
    }
    &::after {
      content: "";
      display: block;
      margin-top: 16px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      display: none;
    }
  }
  .fees-card-form {
    padding: 16px 20px;
  }
  .input-with-icon-label {
    font-size: 16px !important;
    color: #494845 !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
  }
  .input-with-icon-container {
    background-color: #f7f7f7;
    border: 1px solid #d7dbda !important;
    height: 50px;
    border-radius: 7px !important;
    padding-inline-end: 20px;
    .input-with-icon {
      font-size: 16px;
      &.panels {
        height: 100%;
        background: transparent !important;
        flex: 1;
        padding-inline-start: 20px !important;
        color: #6ebf97;
        font-size: 25px;
        font-weight: 600;
        &:focus {
          color: #6ebf97;
        }
        &.border-0 {
          border: none !important;
        }
      }
    }
    .icon {
      color: #494845;
      font-size: 20px;
      min-width: auto;
    }
  }
  .wallet-add-btn {
    background-color: #d39d451a !important;
    color: #d39d45 !important;
    font-size: 16px !important;
    padding: 16px 11px !important;
  }
  .pay-btn {
    min-width: 165px;
    height: 50px;
    background-color: #3f6854 !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}
.bg-card-info {
  background: #2b58420d !important;
}
.flex-1 {
  flex: 1;
}
.bg-card-gray {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background: #2b58420d !important;
  .card-body {
    padding-bottom: 0 !important;
  }
}
.border-header-bottom {
  border-bottom: 1px solid rgba(148, 147, 147, 0.61);
}
.bg-primary-light {
  background: #2b5842bf !important;
}
.payment-mada-img {
  height: 35px;
  width: 55px;
}
.payment-mada-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}
.payment-visa-img {
  height: 25px;
  width: 45px;
}
.payment-visa-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
