<template>
  <div>
    <b-modal
      id="cancelTrip"
      class="main-modal"
      centered
      hide-header
      hide-footer
    >
      <template #modal-header="{ close }">
        <h4 class="font-weight-bold">
          <span class="text-danger">{{ $t("transportation.cancel") }}: </span>
          {{ $t("transportation.trip") }}
        </h4>
        <i class="las la-times closePopupIcon" @click="close()"></i>
      </template>
      <template #default="{ close }">
        <h2 class="font-weight-bold text-danger text-center">
          {{ $t("transportation.cancel") }}
        </h2>
        <P class="text-center">{{ $t("transportation.youSureCancel") }}</P>
        <div class="d-flex justify-content-center mt-3">
          <span class="w-40 p-2">
            <b-button
              @click="cancelTripDone"
              variant="danger"
              class="popupButton w-100"
            >
              <span class="mr-2">{{ $t("transportation.cancel") }}</span>
              <i class="las la-power-off"></i>
            </b-button>
          </span>
          <span class="w-40 p-2">
            <b-button
              variant="white"
              class="popupButton w-100"
              @click="close()"
            >
              <span>لا</span></b-button
            >
          </span>
        </div>
      </template>
    </b-modal>
    <b-container class="my-5" v-if="!showMap">
      <b-row>
        <b-col md="2" class="d-flex justify-content-end">
          <p
            @click="reverseCompnent"
            class="text-warning font-size-16 font-weight-bold cursor-pointer"
            v-if="
              selectedComponent != 'transportationInfo' ||
              selectedComponent != 'final'
            "
          >
            <i class="las la-angle-right font-size-16"></i>
            {{ $t("endUser.back") }}
          </p>
        </b-col>
        <b-col md="8">
          <div class="stepper d-flex justify-content-between mb-2">
            <div
              class="stepper-item position-relative"
              v-for="(status, index) in allComponent"
              :key="index"
              :class="{
                'stepper-item--completed':
                  index <=
                  allComponent.findIndex(
                    (component) => component.value === selectedComponent
                  ),
              }"
            >
              <div
                class="stepper-item__step position-relative"
                :class="{ 'ml-3': index != 0 }"
              >
                <div class="stepper-item__circle">
                  <span v-if="index != 0">{{ index }}</span>
                </div>
                <div class="stepper-item__line"></div>
              </div>
              <div
                class="stepper-item__status text-uppercase"
                v-if="status.value != 'final'"
              >
                {{ status.name }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="2"> </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4">
        <b-col md="12">
          <transition
            name="slide-up"
            appear
            mode="out-in"
            :duration="300"
            class="w-100"
          >
            <keep-alive>
              <component
                :allInfoData="allInfoData"
                :is="selectedComponent"
                @payWithWallet="payWithWallet"
                @changeForm="changeComponent"
              ></component>
            </keep-alive>
          </transition>
        </b-col>
      </b-row>
    </b-container>
    <div v-else>
      <searchView
        :allInfoData="allInfoData"
        @finishRequests="finishRequest"
        :requestTrip="requestTrip"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { core } from "@/config/pluginInit";
import transportationInfo from "../components/transportationSteps/transportationInfo.vue";
import packageChoosen from "../components/transportationSteps/packageChoosen.vue";
import installment from "../components/transportationSteps/installment.vue";
import final from "../components/transportationSteps/final.vue";
import searchView from "./search";
import transportationServices from "@/modules/servicePages/transportation/services/transportation";
import websiteServices from "@/modules/userProfile/services/userProfile";
import Payment from "@/Utils/payment";
export default {
  mounted() {
    core.index();
  },
  components: {
    transportationInfo,
    packageChoosen,
    installment,
    final,
    searchView,
  },
  data() {
    return {
      listenersStarted: false,
      idToken: "",
      // allComponent: [
      //   { value: 'transportationInfo', name: 'بيانات التوصيل' },
      //   { value: 'packageChoosen', name: 'اختيار نوع الشحنة' },
      //   { value: 'installment', name: 'الدفع' },
      //   { value: 'final', name: 'final' }
      // ],
      selectedComponent: "transportationInfo",
      allInfoData: {},
      showMap: false,
      requestTrip: {},
    };
  },
  computed: {
    allComponent() {
      return [
        {
          value: "transportationInfo",
          name: this.$t("transportation.deliveryData"),
        },
        {
          value: "packageChoosen",
          name: this.$t("transportation.chooseVehicleType"),
        },
        { value: "installment", name: this.$t("transportation.payment") },
        { value: "final", name: "final" },
      ];
    },
  },
  methods: {
    async goToPayment(data) {
      sessionStorage.setItem("initiateData", JSON.stringify(data));
      const returnUrl = window.location.origin + "/payment-status";
      const Pay = new Payment(data.gateway.toLowerCase(), data);
      const url = await Pay.paymentConfig();

      if (data.gateway.toLowerCase() === "hyperpay") {
        this.hyperpayForm = true;

        window.wpwlOptions = {
          onReady: function () {
            console.log("HyperPay widget is ready");
          },
          onComplete: function (result) {
            console.log("Payment completed:", result);
          },
        };

        const script = document.createElement("script");
        script.src = url + data.checkout_id;
        script.onload = await function () {
          console.log("HyperPay script loaded");
        };
        document.body.appendChild(script);

        const form = document.createElement("form");
        form.classList.add("paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX");
        form.action = returnUrl;
        document.querySelector(".test-form").appendChild(form);

        this.addEscListener();
      }
    },
    payWithWallet(data) {
      transportationServices
        .pay(this.allInfoData.data.data.id, {
          PIN_code: data.PIN_code,
          payment_method_id: data.id,
        })
        .then((res) => {
          if (+data.id === 2) {
            core.showSnackbar("success", res.data.message);
            this.selectedComponent = "final";
          } else if (+data.id === 1) {
            websiteServices.initiate(res.data).then((res) => {
              this.goToPayment(res.data);
            });
            window.Checkout.showEmbeddedPage("#embed-target1");
          }
        });
    },
    changeComponent(allInfo, showMap = false) {
      this.allInfoData = { ...this.allInfoData, ...allInfo };
      if (!showMap) {
        const indexStepper = this.allComponent.findIndex(
          (stepper) => stepper.value === this.selectedComponent
        );
        this.selectedComponent = this.allComponent[indexStepper + 1].value;
        this.showMap = false;
      } else {
        transportationServices.requestTrip(this.allInfoData).then((res) => {
          core.showSnackbar("success", res.data.message);
          this.requestTrip = res.data.data.id;
          this.showMap = true;
        });
      }
    },
    reserve() {
      const indexStepper = this.allComponent.findIndex(
        (stepper) => stepper.value === this.selectedComponent
      );
      this.selectedComponent = this.allComponent[indexStepper - 1].value;
    },
    openAlertModal() {
      this.$bvModal.show("cancelTrip");
    },
    cancelTripDone() {
      transportationServices
        .closeTrip(this.allInfoData.data.data.id)
        .then((res) => {
          this.reserve();
          this.$bvModal.hide("cancelTrip");
        });
    },
    reverseCompnent() {
      if (this.selectedComponent === "installment") {
        this.openAlertModal();
      } else {
        this.reserve();
      }
    },
    finishRequest(allData) {
      if (allData.status === "success") {
        this.showMap = false;
        this.selectedComponent = "installment";
        this.allInfoData = { ...this.allInfoData, ...allData };
      } else {
        this.$router.push({ name: "transportation" });
      }
    },
  },
};
</script>
<style lang="scss">
.stepper-item__status {
  position: absolute;
  right: 40%;
  top: -14px;
}
.stepper {
  .cancelled-order {
    background: #f00;
    padding: 4px 8px;
    border-radius: 4px;
    span {
      color: #fff;
      font-weight: 600;
    }
  }
  .stepper-item {
    position: relative;
    .stepper-item__circle {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--iq-secondary);
      color: #fff;
      z-index: 2;
      font-weight: bold;
      font-size: 12px;
      line-height: 0;
    }
    .stepper-item__status {
      color: var(--iq-secondary);
    }
    &:not(:last-child) {
      flex-grow: 1;
      .stepper-item__line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: calc(100% + 15px);
        height: 4px;
        background: var(--iq-secondary);
      }
    }
    @media (max-width: 991px) {
      .stepper-item__circle {
        width: 20px;
        height: 20px;
        i {
          font-size: 0.8rem;
        }
      }
      .stepper-item__status {
        font-size: 12px;
      }
    }
    &.stepper-item--completed {
      .stepper-item__circle {
        background: var(--iq-primary);
        box-shadow: inset 0px 3px 6px #00000096;
      }
      .stepper-item__status {
        font-weight: bold;
        color: var(--iq-primary);
      }
      .stepper-item__line {
        background: var(--iq-primary);
      }
    }
  }
}
[dir="rtl"] .stepper-item {
  .stepper-item__line {
    right: 0px;
    left: auto;
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.6s ease-in-out;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
.closePopupIcon {
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}
.popupButton {
  border-radius: 4px !important;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  font-weight: bolder;
  padding: 15px !important;
}
.popupButton span {
  font-weight: bold;
}
.w-40 {
  width: 40%;
}
</style>
